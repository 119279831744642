import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { VelocityComponent } from 'velocity-react';
import styles from './accordion.scss';
import { KEY_ENTER, PDF_RENDER_MODE } from '../../constants';
import {
    asClassComponent,
    withRenderModeProp,
} from '../hocs';

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.animatorRef = React.createRef();
        this.beginOpen = props.isOpen;
    }

    // Remove the height inline style because it can interfere
    // with animations after a resize or when an animation is
    // ended prematurely.
    clearHeightStyle() {
        this.animatorRef.current.style.height = null;
    }

    handleClick(ev) {
        ev.preventDefault();
        this.props.onToggleInteract(ev);
    }

    handleKeyDown(ev) {
        if (ev.keyCode === KEY_ENTER) {
            this.props.onToggleInteract(ev);
        }
    }

    render() {
        const {
            titleTag,
            title,
            headingId,
            isOpen,
            children,
            renderMode,
        } = this.props;

        if (!title) {
            return null;
        }

        const accordionClasses = [styles.accordion, 'u-page-break-inside-avoid'];
        if (isOpen) { accordionClasses.push(styles.isOpen); }
        const accordionClassName = accordionClasses.join(' ');

        const headerClasses = [
            styles.header,
            'dark-blue',
            ...(renderMode === PDF_RENDER_MODE ?
                ['type-large-paragraph', 'type-large-paragraph--bold', 'u-uppercase'] :
                []
            ),
        ];
        const headerClassName = headerClasses.join('  ');
        const toggleClassName = `${styles.toggle} icon icon-plus`;

        const Tag = titleTag;

        return (
            <div className={accordionClassName}>
                {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
                <Tag
                    {...(headingId ? { id: headingId } : {})}
                    className={headerClassName}
                    tabIndex="0"
                    onKeyDown={ev => this.handleKeyDown(ev)}
                    onClick={ev => this.handleClick(ev)}>
                    <span className={styles.title}>
                        <span>{title}</span>
                        <span className={toggleClassName} />
                    </span>
                </Tag>
                {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
                <VelocityComponent
                    animation={isOpen ? 'slideDown' : 'slideUp'}
                    complete={() => this.clearHeightStyle()}>
                    <div
                        className={styles.contentWrapper}
                        ref={this.animatorRef}
                        style={{ display: this.beginOpen ? 'block' : 'none' }}>
                        <div className={`${renderMode === PDF_RENDER_MODE ? 'u-margin-top-xsmall' : 'u-padding-top-small'}`}>
                            {children}
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        );
    }
}

Accordion.propTypes = {
    titleTag: PropTypes.string,
    title: PropTypes.string,
    headingId: PropTypes.string,
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    onToggleInteract: PropTypes.func,
    renderMode: PropTypes.string,
};

Accordion.defaultProps = {
    titleTag: 'h3',
    title: '',
    headingId: '',
    isOpen: false,
    children: null,
    onToggleInteract: _.noop,
    renderMode: '',
};

const withRenderModePropAccordion = asClassComponent(withRenderModeProp(Accordion));
withRenderModePropAccordion.propTypes = _.omit(Accordion.propTypes, ['renderMode']);
withRenderModePropAccordion.defaultProps = Accordion.defaultProps;

export default withRenderModePropAccordion;
