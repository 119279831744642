import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

import { withDefaultLayoutAndGlobals } from '../layouts/TemplateBase.jsx';
import { valueHasData } from '../../utils/data';
import { getFormattedUtcDate } from '../../utils/date';
import MaybeLink from '../elements/MaybeLink.jsx';
import RawMarkup from '../elements/RawMarkup.jsx';
import ConditionalRender from '../elements/ConditionalRender.jsx';
import GatedContentWrapper from './news/GatedContentWrapper.jsx';
import PlainHeader from '../elements/PlainHeader/index.jsx';
import Nav from '../elements/Nav/index.jsx';
import PageEyebrow from '../elements/PageEyebrow.jsx';
import EventInformation from './news/EventInformation.jsx';
import Repeater from '../elements/Repeater.jsx';
import PeopleListings from '../elements/PeopleListing/PeopleListings.jsx';
import LinkList from '../elements/LinkList/index.jsx';
import Listings from '../elements/Listings/index.jsx';
import Resources from '../elements/Resources/index.jsx';
import FullWidthButton from '../elements/Forms/FullWidthButton.jsx';
import Carousel from '../elements/Carousel/index.jsx';
import styles from './news/news.scss';
import { withRenderModeProp } from '../hocs';
import {
    DATE_FORMAT_FULL_MONTH_YEAR,
    PDF_RENDER_MODE,
} from '../../constants';

function resourcesContainerClassesFromProps({ renderMode }) {
    const containerClasses = [
        'l-standard-width',
        'u-page-break-inside-avoid',
        ...(renderMode !== PDF_RENDER_MODE ?
            [
                'u-padding-med',
                'bg-dark-blue',
                'clearfix',
            ] :
            []
        ),
    ];

    return containerClasses.join(' ');
}

class NewsTemplate extends React.Component {
    static getRepeaterPropsForType(type) {
        const repeaterTypeProps = {
            captioned_media: {
                extraClasses: [
                    'bg-off-white',
                ],
            },
        };

        return repeaterTypeProps[type] || {};
    }

    getHeaderGradientClass() {
        const isEvent = this.props.isEvent;
        const headerClass = isEvent ? 'u-gradient-deep-ocean' : 'u-gradient-ocean';

        return headerClass;
    }

    getHeaderTitleColorClass() {
        const { props } = this;
        const isEvent = props.isEvent;
        const isPdf = props.renderMode === PDF_RENDER_MODE;
        const colorClass = isEvent && !isPdf ? 'dark-blue' : 'light-blue';

        return colorClass;
    }

    getColumnAlignmentClass() {
        const isEvent = this.props.isEvent;
        const alignmentClass = isEvent ? '' : styles.newsColumns;

        return alignmentClass;
    }

    maybeRenderNav(targetRenderMode = '') {
        const { props } = this;
        const { renderMode } = props;

        const doRenderNav = renderMode === targetRenderMode || (!renderMode && !targetRenderMode);

        if (doRenderNav) {
            return (
                <Nav
                    {...props.topNav}
                    window={props.window}
                    renderMode={renderMode}
                />
            );
        }

        return null;
    }

    // We want to display the "Add to Calendar" button only if the current time is before the end of the event
    // If the event's end date/time is available, use it, otherwise default use the start date/time
    // If the date/time has a timezone available, then use the provided time as the time to compare the current time against
    // If there is no timezone available, then we assume that the date is provided in EST, and compare the current time to end of the day in EST (11:59:59PM EST)
    // * We default to EST because the client's HQ is located on the east coast
    isCurrentTimeBeforeEndOfEvent() {
        const { props } = this;
        const {
            datetimeInformation,
        } = props;

        const DEFAULT_TIME_ZONE = 'America/New_York';

        const rawStartTime = _.get(datetimeInformation, 'rawStartTime');
        const rawEndTime = _.get(datetimeInformation, 'rawEndTime');
        const startTimeZone = _.get(datetimeInformation, 'startTimeZone');
        const endTimeZone = _.get(datetimeInformation, 'endTimeZone');

        const eventEndDateTime = rawEndTime || rawStartTime;
        const eventTimeZone = rawEndTime ? endTimeZone
            : rawStartTime ? startTimeZone
                : undefined;

        // In the case that no time is provided, we retrieve the end of the date in EST
        // RubyApps returns all datetimes in UTC even if there is no date provided (the time would just be T00:00:000Z)
        // However, if we convert this UTC time to EST, it would go back one day, since EST is GMT-4/5
        // And if we take the end of the day, then we would find the end of the day of the previous day
        // To prevent this from happening, we truncate the time portion of the date first BEFORE converting to EST
        // So that once we convert to EST, the time will 00:00:000 EST, then we can take the end of day
        const eventEndDateWithoutTime = moment.utc(eventEndDateTime).format('YYYY-MM-DD');
        const endOfEventEndDate_inEST = moment.tz(eventEndDateWithoutTime, DEFAULT_TIME_ZONE).endOf('day');

        const currentTimeIsBeforeEndOfEvent = eventTimeZone ? moment().isBefore(eventEndDateTime)
            : moment().isBefore(endOfEventEndDate_inEST);

        return currentTimeIsBeforeEndOfEvent;
    }

    renderPageTitleContent_forNews() {
        return this.props.isEvent ? null : this.renderPageTitleContent();
    }

    renderPageTitleContent_forEvent() {
        return this.props.isEvent ? this.renderPageTitleContent() : null;
    }

    renderSource() {
        const { source } = this.props;

        if (!(source && (source.link || source.text))) {
            return null;
        }

        return (
            <div className={[
                'type-small-paragraph',
                'u-margin-bottom-vsmall',
                'gray',
            ].join(' ')}
            >
                <MaybeLink
                    className={source.link ? 'u-standard-link-underline' : ''}
                    href={source.link}
                >
                    {source.text}
                </MaybeLink>
            </div>
        );
    }

    renderPageTitleContent() {
        const titleClasses = [
            'u-padding-bottom-med',
            this.getHeaderTitleColorClass(),
            'type-h2',
            ...(this.props.renderMode === PDF_RENDER_MODE ?
                ['type-h1', 'type-h1--bold'] :
                ['type-h2']),
        ].filter(className => className).join(' ');
        return (
            <React.Fragment>
                <h2 className={titleClasses}>
                    <RawMarkup>
                        {this.props.title_html}
                    </RawMarkup>
                </h2>
                <ConditionalRender condition={this.props.subtitle_html}>
                    <div className={`type-article-sub-header u-margin-bottom-vsmall ${this.getHeaderTitleColorClass()}`}>
                        <RawMarkup>{this.props.subtitle_html}</RawMarkup>
                    </div>
                </ConditionalRender>
                {this.renderSource()}
            </React.Fragment>
        );
    }

    renderEventHeading() {
        const { props } = this;
        if (!props.isEvent) {
            return null;
        }

        let eventHeadingElements = [];

        const eventWrapperClasses = (props.renderMode === PDF_RENDER_MODE ?
            [] :
            ['u-padding-bottom-med']
        ).join(' ');
        const eventInformation = (
            <div className={eventWrapperClasses}>
                <EventInformation
                    {...props.locationProps}
                    {...props.registrationProps}
                    datetimeInformation={props.datetimeInformation}
                    renderMode={props.renderMode}
                />
            </div>
        );
        const eventPageTitle = this.renderPageTitleContent_forEvent();
        eventHeadingElements = props.renderMode === PDF_RENDER_MODE ?
            [eventPageTitle, eventInformation] :
            [eventInformation, eventPageTitle];

        return eventHeadingElements;
    }

    render() {
        const { props } = this;
        const {
            datetimeInformation,
            disclaimer,
            carousel,
        } = this.props;

        return (
            <React.Fragment>
                {this.maybeRenderNav(PDF_RENDER_MODE)}
                <ConditionalRender condition={props.renderMode !== PDF_RENDER_MODE}>
                    <PlainHeader
                        color="medium-blue"
                        renderMode={props.renderMode}
                        {...props.headerProps}
                    />
                </ConditionalRender>
                {this.maybeRenderNav()}

                <div className="l-page-container bg-white">
                    <PageEyebrow
                        breadcrumbLinks={props.breadcrumbLinks}
                        utilityNavProps={props.utilityNavProps}
                        additionalClasses={['u-margin-bottom-small']}
                    />

                    <main>
                        <section className="l-standard-width u-remove-pdf-padding">
                            <ConditionalRender condition={props.isEvent}>
                                {this.renderEventHeading()}
                            </ConditionalRender>
                            <article className={`l-2-columns u-margin-bottom-large ${this.getColumnAlignmentClass()}`}>
                                <div className="l-1m-1t-2d-wide-column-item">
                                    {this.renderPageTitleContent_forNews()}
                                    <ConditionalRender condition={!props.isEvent}>
                                        <div className={`${props.renderMode === PDF_RENDER_MODE ? 'type-large-paragraph type-large-paragraph--bold u-pdf-heading-underline dark-blue' : 'type-small-paragraph gray'} u-margin-bottom-vsmall`}>
                                            <time dateTime={props.datetimeInformation.rawStartTime}>
                                                {
                                                    props.datetimeInformation.displayDate ||
                                                    getFormattedUtcDate(props.datetimeInformation.rawStartTime, DATE_FORMAT_FULL_MONTH_YEAR)}
                                            </time>
                                        </div>
                                    </ConditionalRender>
                                    {
                                      carousel && carousel.length > 0 &&
                                      <div className="u-margin-bottom-large">
                                        <Carousel slides={carousel} />
                                      </div>
                                    }
                                    <ConditionalRender condition={props.main_content}>
                                        <div className={`rte u-padding-bottom-med ${props.renderMode === PDF_RENDER_MODE ? 'u-padding-top-small' : ''}`}>
                                            <RawMarkup>
                                                {props.main_content}
                                            </RawMarkup>
                                            <ConditionalRender condition={disclaimer}>
                                                <p>
                                                    <RawMarkup>
                                                        {disclaimer}
                                                    </RawMarkup>
                                                </p>
                                            </ConditionalRender>
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={props.show_gated_form}>
                                        <GatedContentWrapper
                                            gated_content_title={props.gated_content_title}
                                            gated_content_header={props.gated_content_header}
                                            gated_content_main={props.gated_content_main}
                                            gated_content_success_message={props.gated_content_success_message}
                                            recaptchaKey={props.recaptchaKey}
                                            pageId={props.id}
                                        />
                                    </ConditionalRender>
                                    {props.repeaterData.map((repeaterEntry, idx) => (
                                        <div className="u-margin-bottom-med">
                                            <Repeater
                                                type={repeaterEntry.type}
                                                props={{
                                                    hasSidebar: true,
                                                    ...NewsTemplate.getRepeaterPropsForType(repeaterEntry.type),
                                                    ...repeaterEntry.props,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <aside className="l-1m-1t-2d-small-column-item">
                                    {props.isEvent
                                        && (_.get(datetimeInformation, 'rawEndTime') || _.get(datetimeInformation, 'rawStartTime'))
                                        && this.isCurrentTimeBeforeEndOfEvent(datetimeInformation)
                                        &&
                                        <div className={`${styles.calendarButton} u-hide-in-print`}>
                                            <FullWidthButton
                                                color="LightBlue"
                                                href={props.calendarUrl}
                                                text="Add To Calendar"
                                            />
                                        </div>
                                    }
                                    <ConditionalRender condition={valueHasData(props.contacts)}>
                                        <div className="u-margin-bottom-large  clearfix">
                                            <PeopleListings
                                                title="Contacts"
                                                tag="h4"
                                                {...props.contacts}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.eventContacts)}>
                                        <div className="u-margin-bottom-large  clearfix">
                                            <PeopleListings
                                                title="Event Contacts"
                                                tag="h4"
                                                {...props.eventContacts}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.speakers)}>
                                        <div className="u-margin-bottom-large  clearfix">
                                            <PeopleListings
                                                title="Speakers"
                                                tag="h4"
                                                {...props.speakers}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.authors)}>
                                        <div className="u-margin-bottom-med-static u-page-break-inside-avoid">
                                            <LinkList
                                                title="Authors"
                                                links={props.authors}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.relatedAttorneys)}>
                                        <div className="u-margin-bottom-med-static u-page-break-inside-avoid">
                                            <LinkList
                                                title="Related Professionals"
                                                links={props.relatedAttorneys}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.pressContacts) && props.renderMode !== PDF_RENDER_MODE}>
                                        <div className="u-margin-bottom-med-static u-page-break-inside-avoid">
                                            <LinkList
                                                title="Press Contact"
                                                links={props.pressContacts}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.relatedServices)}>
                                        <div className="u-margin-bottom-med-static u-page-break-inside-avoid">
                                            <LinkList
                                                title="Related Practices and Industries"
                                                links={props.relatedServices}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.relatedOffices)}>
                                        <div className="u-margin-bottom-med-static u-page-break-inside-avoid">
                                            <LinkList
                                                title="Related Offices"
                                                links={props.relatedOffices}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>
                                </aside>
                            </article>
                        </section>
                        <aside>
                            <ConditionalRender condition={valueHasData(props.resources) && props.renderMode !== PDF_RENDER_MODE}>
                                <div className={resourcesContainerClassesFromProps(props)}>
                                    <Resources
                                        title="Resources"
                                        resources={props.resources}
                                        renderMode={props.renderMode}
                                        extraClasses={[
                                            'type-results-header',
                                            'u-margin-bottom-med',
                                            ...(props.renderMode === PDF_RENDER_MODE ?
                                                [
                                                    'type-large-paragraph',
                                                    'type-large-paragraph--bold',
                                                    'u-pdf-heading-underline',
                                                ] :
                                                ['white']
                                            ),
                                        ]}
                                    />
                                </div>
                            </ConditionalRender>
                            <ConditionalRender condition={valueHasData(props.relatedInsights) && props.renderMode !== PDF_RENDER_MODE}>
                                <div className="l-full-width-mobile u-margin-top-med u-margin-bottom-med">
                                    <Listings
                                        title="Related Insights"
                                        listings={props.relatedInsights}
                                        titleExtraClasses={[
                                            'u-padding-mobile-only',
                                        ]}
                                        url={props.relatedInsightsUrl}
                                    />
                                </div>
                            </ConditionalRender>
                        </aside>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

NewsTemplate.propTypes = {
    headerProps: PropTypes.shape(PlainHeader.propTypes),
    topNav: PropTypes.shape(Nav.propTypes),
    breadcrumbLinks: PageEyebrow.propTypes.breadcrumbLinks,
    utilityNavProps: PageEyebrow.propTypes.utilityNavProps,
    title_html: PropTypes.string,
    subtitle_html: PropTypes.string,
    main_content: PropTypes.string,
    subitle_html: PropTypes.string,
    source: PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
    }),
    repeaterData: PropTypes.arrayOf(PropTypes.shape(Repeater.propTypes)),
    contacts: PropTypes.shape(PeopleListings.propTypes),
    eventContacts: PropTypes.shape(PeopleListings.propTypes),
    speakers: PropTypes.shape(PeopleListings.propTypes),
    relatedAttorneys: LinkList.propTypes.links,
    pressContacts: LinkList.propTypes.links,
    relatedOffices: LinkList.propTypes.links,
    relatedServices: LinkList.propTypes.links,
    relatedInsights: Listings.propTypes.listings,
    relatedInsightsUrl: Listings.propTypes.url,
    isEvent: PropTypes.bool,
    locationProps: PropTypes.shape({
        locationName_html: EventInformation.propTypes.locationName_html,
        additionalLocationLines: EventInformation.propTypes.additionalLocationLines,
    }),
    registrationProps: PropTypes.shape({
        registrationText: PropTypes.string,
        registrationLink: PropTypes.string,
    }),
    datetimeInformation: EventInformation.propTypes.datetimeInformation,
    resources: Resources.propTypes.resources,
    calendarUrl: PropTypes.string,
    renderMode: PropTypes.string,
    show_gated_content: PropTypes.bool,
    gated_content_title: PropTypes.string,
    gated_content_header: PropTypes.string,
    gated_content_main: PropTypes.string,
    gated_content_success_message: PropTypes.string,
    recaptchaKey: PropTypes.string,
    disclaimer: PropTypes.string,
    carousel: Carousel.propTypes.slides,
};

NewsTemplate.defaultProps = {
    headerProps: {},
    topNav: {},
    breadcrumbLinks: PageEyebrow.defaultProps.breadcrumbLinks,
    utilityNavProps: PageEyebrow.defaultProps.utilityNavProps,
    title_html: '',
    subtitle_html: '',
    main_content: '',
    subitle_html: '',
    source: {},
    repeaterData: [],
    contacts: {},
    eventContacts: {},
    speakers: {},
    relatedAttorneys: LinkList.defaultProps.links,
    pressContacts: LinkList.defaultProps.links,
    relatedOffices: LinkList.defaultProps.links,
    relatedServices: LinkList.defaultProps.links,
    relatedInsights: Listings.defaultProps.listings,
    relatedInsightsUrl: Listings.defaultProps.url,
    isEvent: false,
    locationProps: {},
    registrationProps: {},
    datetimeInformation: {},
    resources: Resources.defaultProps.resources,
    calendarUrl: '',
    renderMode: '',
    show_gated_content: false,
    gated_content_title: '',
    gated_content_header: '',
    gated_content_main: '',
    gated_content_success_message: '',
    recaptchaKey: '',
    carousel: Carousel.defaultProps.slides,
};

export default withDefaultLayoutAndGlobals(withRenderModeProp(NewsTemplate));
