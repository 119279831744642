import React from 'react';
import PropTypes from 'prop-types';
import { qualifyUrlInWindow } from '../../utils/url';

function Favicons({ window }) {
    return (
        <React.Fragment>
            <link rel="apple-touch-icon" sizes="180x180" href={qualifyUrlInWindow('/images/favicons/apple-touch-icon.png?v2', window)} />
            <link rel="icon" type="image/png" sizes="32x32" href={qualifyUrlInWindow('/images/favicons/favicon-32x32.png?v2', window)} />
            <link rel="icon" type="image/png" sizes="16x16" href={qualifyUrlInWindow('/images/favicons/favicon-16x16.png?v2', window)} />
            <link rel="manifest" href={qualifyUrlInWindow('/images/favicons/site.webmanifest?v2', window)} />
            <link rel="mask-icon" href={qualifyUrlInWindow('/images/favicons/safari-pinned-tab.svg?v2', window)} color="#0091da" />
            <link rel="shortcut icon" href={qualifyUrlInWindow('/images/favicons/favicon.ico?v2', window)} />
            <meta name="apple-mobile-web-app-title" content="Troutman Pepper Locke" />
            <meta name="application-name" content="Troutman Pepper Locke" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content={qualifyUrlInWindow('/images/favicons/mstile-144x144.png?v2', window)} />
            <meta name="msapplication-config" content={qualifyUrlInWindow('/images/favicons/browserconfig.xml?v2', window)} />
            <meta name="theme-color" content="#ffffff" />
        </React.Fragment>
    );
}

Favicons.propTypes = {
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Favicons;
