import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import RawMarkup from "../RawMarkup.jsx";
import QualifiedLink from "../QualifiedLink.jsx";
import { withUrlsQualified } from "../../hocs.js";
import { PDF_RENDER_MODE } from "../../../constants.js";

import styles from "./styles.scss";

function getStyleClassNames(props) {
  const { color, asGradient } = props;
  return {
    styleClassName: classNames(
      color,
      styles.colorWhiteInMobile,
      asGradient
        ? `u-gradient-${color} u-gradient-mobile-only`
        : `bg-${color} u-bg-mobile-only`,
    ),
    skewClassName: asGradient ? `u-gradient-${color}` : `bg-${color}`,
  };
}

function PlainHeader(props) {
  const {
    asGradient,
    color,
    title_html: title,
    renderMode,
  } = props;

  if (renderMode === PDF_RENDER_MODE) {
    return (
      <React.Fragment>
        <h1 className="type-h1 u-font-weight-normal"><span className="rte"><RawMarkup>{title}</RawMarkup></span></h1>
        <div className={styles.gradientSeparator} />
      </React.Fragment>
    );
  }

  const ImgWithSrcQualified = withUrlsQualified("img", ["src"]);
  const {
    styleClassName,
    skewClassName,
  } = getStyleClassNames(props);

  return (
    <div
      className={classNames(
        "l-standard-width l-standard-width--no-padding",
        styleClassName,
        styles.header,
      )}
    >
      <QualifiedLink href="/" className={styles.logo}>
        <ImgWithSrcQualified src={"/images/logo-normal.svg"} alt="Troutman Pepper Locke" />
      </QualifiedLink>
      <div className="l-standard-width">
      <h1 className={classNames("type-h1 u-font-weight-normal", styles.title)}>
        <span className="rte">
          <RawMarkup>{title}</RawMarkup>
        </span>
      </h1>
      </div>
      <div className={classNames(skewClassName, styles.skew)}/>
    </div>
  );
}

PlainHeader.propTypes = {
  asGradient: PropTypes.bool,
  color: PropTypes.string,
  renderMode: PropTypes.string,
  title_html: PropTypes.string,
};

PlainHeader.defaultProps = {
  color: "medium-blue",
  renderMode: "",
};

export default PlainHeader;
