import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withDefaultLayoutAndGlobals } from '../layouts/TemplateBase.jsx';
import RawMarkup from '../elements/RawMarkup.jsx';
import PlainHeader from '../elements/PlainHeader/index.jsx';
import Nav from '../elements/Nav/index.jsx';
import PageEyebrow from '../elements/PageEyebrow.jsx';
import ViewMore from '../elements/ViewMore.jsx';
import ConditionalRender from '../elements/ConditionalRender.jsx';
import StatefulAccordion from '../elements/StatefulAccordion.jsx';
import PeopleListings from '../elements/PeopleListing/PeopleListings.jsx';
import LinkList from '../elements/LinkList/index.jsx';
import Resources from '../elements/Resources/index.jsx';
import Listings from '../elements/Listings/index.jsx';
import Repeater from '../elements/Repeater.jsx';
import Carousel from '../elements/Carousel/index.jsx';
import { valueHasData } from '../../utils/data';
import { PDF_RENDER_MODE } from '../../constants';
import { withRenderModeProp } from '../hocs';
import Statistics from '../elements/Statistics/index.jsx';

function resourcesContainerClassesFromProps({ renderMode }) {
    const containerClasses = [
        'l-standard-width',
        'u-margin-bottom-med',
        'u-remove-pdf-padding',
        'u-page-break-inside-avoid',
        ...(renderMode !== PDF_RENDER_MODE ?
            [
                'u-padding-med',
                'bg-dark-blue',
            ] :
            []
        ),
    ];

    return containerClasses.join(' ');
}

class ServiceTemplate extends React.Component { // eslint-disable-line react/prefer-stateless-function
    static getRepeaterPropsForType(type) {
        const repeaterTypeProps = {
            captioned_media: {
                extraClasses: [
                    'bg-off-white',
                ],
            },
        };

        return repeaterTypeProps[type] || {};
    }

    maybeRenderNav(targetRenderMode = '') {
        const { props } = this;
        const renderMode = props.renderMode;

        const doRenderNav = renderMode === targetRenderMode || (!renderMode && !targetRenderMode);

        if (doRenderNav) {
            return (
                <Nav
                    {...props.topNav}
                    window={props.window}
                    renderMode={renderMode}
                />
            );
        }

        return null;
    }

    maybeRenderPDFHeader(targetRenderMode = '') {
        const { props } = this;
        const renderMode = props.renderMode;
        const doRenderPDFHeader = renderMode === targetRenderMode || (!renderMode && !targetRenderMode);

        if (doRenderPDFHeader) {
            return (
                <div className="u-justify-self-start u-uppercase type-large-paragraph type-large-paragraph--bold u-pdf-padding-bottom-xsmall dark-blue">
                    Overview
                </div>
            );
        }

        return null;
    }

    render() {
        const { props } = this;
        const { carousel } = props;
        return (
            <React.Fragment>
                {this.maybeRenderNav(PDF_RENDER_MODE)}
                <PlainHeader
                    color='medium-blue'
                    renderMode={props.renderMode}
                    {...props.headerProps}
                />
                {this.maybeRenderNav()}

                <div className="l-page-container  bg-white">
                    <PageEyebrow
                        breadcrumbLinks={props.breadcrumbLinks}
                        utilityNavProps={props.utilityNavProps}
                    />

                    <main>
                        <section>
                            <div className="l-standard-width l-2-columns u-margin-bottom-large u-remove-pdf-padding">
                                <div className="l-1m-1t-2d-wide-column-item">
                                    {
                                      carousel && carousel.length > 0 &&
                                      <div className="u-margin-bottom-med">
                                        <Carousel slides={carousel} />
                                      </div>
                                    }
                                    <ConditionalRender condition={props.main_content}>
                                        {this.maybeRenderPDFHeader(PDF_RENDER_MODE)}
                                        <div className={`u-padding-bottom-med u-remove-print-vertical-padding ${props.renderMode === PDF_RENDER_MODE ? 'u-margin-top-xsmall' : ''}`}>
                                            <div className="rte">
                                                <RawMarkup>{props.main_content}</RawMarkup>
                                            </div>
                                            <ConditionalRender condition={props.view_more}>
                                                <ViewMore
                                                    openText="View Full Description"
                                                    closeText="View Less"
                                                    additionalButtonClassNames={['u-margin-top-small']}
                                                >
                                                    <div className="rte">
                                                        <RawMarkup>{props.view_more}</RawMarkup>
                                                    </div>
                                                </ViewMore>
                                            </ConditionalRender>
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender condition={valueHasData(props.repeaterData)}>
                                        {this.props.repeaterData.map((repeaterEntry, idx) => (
                                            <div className="u-padding-bottom-med" key={idx}>
                                                <Repeater
                                                    type={repeaterEntry.type}
                                                    props={{
                                                        hasSidebar: true,
                                                        ...ServiceTemplate.getRepeaterPropsForType(repeaterEntry.type),
                                                        ...repeaterEntry.props,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </ConditionalRender>

                                    <ConditionalRender condition={valueHasData(props.experience)}>
                                        <StatefulAccordion title="Representative Matters">
                                            {props.experience.map((experienceEntry, idx) => (
                                                <div className="u-padding-bottom-med" key={idx}>
                                                    <div className="rte">
                                                        <RawMarkup>{experienceEntry.intro_html}</RawMarkup>
                                                    </div>
                                                    <ConditionalRender condition={experienceEntry.view_more_html}>
                                                        <ViewMore
                                                            openText="View More"
                                                            closeText="View Less"
                                                            additionalButtonClassNames={['u-margin-top-small']}
                                                        >
                                                            <div className="rte">
                                                                <RawMarkup>{experienceEntry.view_more_html}</RawMarkup>
                                                            </div>
                                                        </ViewMore>
                                                    </ConditionalRender>
                                                </div>
                                            ))}
                                        </StatefulAccordion>
                                    </ConditionalRender>

                                    <ConditionalRender condition={valueHasData(props.accordions)}>
                                        {props.accordions.map((accordion, idx) => (
                                            <ConditionalRender condition={accordion.title && accordion.content_html} key={idx}>
                                                <StatefulAccordion title={accordion.title} key={idx}>
                                                    <div className="rte">
                                                        <RawMarkup>{accordion.content_html}</RawMarkup>
                                                    </div>
                                                </StatefulAccordion>
                                            </ConditionalRender>
                                        ))}
                                    </ConditionalRender>
                                </div>
                                <aside className="l-1m-1t-2d-small-column-item">
                                    <PeopleListings
                                        title="Contacts"
                                        tag="h4"
                                        renderAdditionalTitle={true}
                                        {...props.peopleListings}
                                        extraClasses={['u-margin-bottom-med-static']}
                                        renderMode={props.renderMode}
                                    />

                                    <ConditionalRender condition={valueHasData(props.relatedServices)}>
                                        <div className="u-reset-first-child-whitespace dark-blue u-page-break-inside-avoid">
                                            <LinkList
                                                title="Related Practices and Industries"
                                                links={props.relatedServices}
                                                renderMode={props.renderMode}
                                            />
                                        </div>
                                    </ConditionalRender>

                                </aside>
                            </div>

                            <ConditionalRender condition={valueHasData(props.resources)}>
                                <div className={resourcesContainerClassesFromProps(props)}>
                                    <Resources
                                        title="Resources"
                                        resources={props.resources}
                                        renderMode={props.renderMode}
                                        extraClasses={[
                                            'type-results-header',
                                            ...(props.renderMode === PDF_RENDER_MODE ?
                                                [
                                                    'type-large-paragraph',
                                                    'type-large-paragraph--bold',
                                                    'u-pdf-heading-underline',
                                                ] :
                                                [
                                                    'white',
                                                    'u-margin-bottom-med',

                                                ]
                                            ),
                                        ]}
                                    />
                                </div>
                            </ConditionalRender>
                            <ConditionalRender condition={valueHasData(props.relatedMatters)}>
                                <div className="l-full-width-mobile u-margin-bottom-med u-remove-pdf-padding">
                                    <Listings
                                        title="Related Experience"
                                        listings={props.relatedMatters}
                                        titleExtraClasses={[
                                            'u-padding-mobile-only',
                                        ]}
                                        url={props.relatedMattersUrl}
                                        renderMode={props.renderMode}
                                        viewAllText="View All Experience"
                                    />
                                </div>
                            </ConditionalRender>
                            <ConditionalRender condition={valueHasData(props.relatedInsights)}>
                                <div className={classNames(
                                    'l-full-width-mobile u-margin-bottom-med u-remove-pdf-padding',
                                    valueHasData(props.relatedMatters) && 'u-margin-top-large',
                                )}>
                                    <Listings
                                        title="Related Insights"
                                        listings={props.relatedInsights}
                                        titleExtraClasses={[
                                            'u-padding-mobile-only',
                                        ]}
                                        url={props.relatedInsightsUrl}
                                        renderMode={props.renderMode}
                                    />
                                </div>
                            </ConditionalRender>
                        </section>
                        <section>
                            <ConditionalRender condition={valueHasData(this.props.stats)}>
                                <Statistics stats={this.props.stats}/>
                            </ConditionalRender>
                        </section>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

ServiceTemplate.propTypes = {
    topNav: PropTypes.shape(Nav.propTypes),
    relatedAttorneyUrl: PropTypes.string,
    relatedInsightsUrl: PropTypes.string,
    main_content: PropTypes.string,
    view_more: PropTypes.string,
    repeaterData: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    stats: PropTypes.arrayOf(PropTypes.shape({
        stat: PropTypes.string,
        text: PropTypes.string,
    })),
    experience: PropTypes.arrayOf(PropTypes.shape({
        intro_html: PropTypes.string,
        view_more_html: PropTypes.string,
    })),
    accordions: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content_html: PropTypes.string,
    })),
    peopleListings: PropTypes.shape(PeopleListings.propTypes),
    relatedServices: LinkList.propTypes.links,
    relatedInsights: Listings.propTypes.listings,
    relatedMatters: Listings.propTypes.listings,
    relatedMattersUrl: Listings.propTypes.url,
    renderMode: PropTypes.string,
    carousel: Carousel.propTypes.slides,
};

ServiceTemplate.defaultProps = {
    topNav: {},
    relatedAttorneyUrl: '',
    relatedInsightsUrl: '',
    main_content: '',
    view_more: '',
    repeaterData: [],
    stats: [],
    experience: [],
    accordions: [],
    peopleListings: {},
    relatedServices: LinkList.defaultProps.links,
    relatedInsights: Listings.defaultProps.listings,
    renderMode: '',
    carousel: Carousel.defaultProps.slides,
};

const withRenderModePropService = withRenderModeProp(ServiceTemplate);
export default withDefaultLayoutAndGlobals(withRenderModePropService);
