import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function LinkList({ title, links, renderMode }) {
    if (!title || links.length === 0) {
        return null;
    }

    const titleClasses = renderMode === PDF_RENDER_MODE ?
        'type-large-paragraph type-large-paragraph--bold u-uppercase dark-blue' :
        'type-callout-subtitle  dark-blue';

    return (
        <React.Fragment>
            <h4 className={titleClasses}>
                {title}
            </h4>
            <ul>
                {links.map((link, idx) => (
                    <ListItem key={idx} {...link} renderMode={renderMode} />
                ))}
            </ul>
        </React.Fragment>
    );
}

LinkList.propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape(ListItem.propTypes)),
    renderMode: PropTypes.string,
};

LinkList.defaultProps = {
    title: '',
    links: [],
    renderMode: '',
};

export default LinkList;
