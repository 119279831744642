import React from 'react';
import PropTypes from 'prop-types';
import PeopleListing from './PeopleListing.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import LearnMore from '../LearnMore.jsx';
import { PDF_RENDER_MODE } from '../../../constants';

function titleClassesFromRenderMode(renderMode) {
    const commonClasses = [
        'u-margin-bottom-vsmall',
        'u-reset-first-child-whitespace',
        'dark-blue',
        'u-uppercase',
    ];

    if (renderMode === PDF_RENDER_MODE) {
        commonClasses.push(...[
            'type-large-paragraph',
            'type-large-paragraph--bold',
        ]);
    } else {
        commonClasses.push(...[
            'type-small-caps',
            'u-letter-spacing-1',
        ]);
    }

    return commonClasses.join(' ');
}

function PeopleListings({
    title,
    tag,
    people,
    renderAdditionalTitle,
    extraClasses,
    viewAllText,
    url,
    renderMode,
}) {
    if (!people.length && !url) {
        return null;
    }

    const Tag = tag;

    return (
        <div className={`${extraClasses.join(' ')} u-page-break-inside-avoid`}>
            <ConditionalRender condition={title}>
                <Tag className={titleClassesFromRenderMode(renderMode)}>
                    {title}
                </Tag>
            </ConditionalRender>
            <div className="clearfix">
                {people.map((peopleProps, idx) => (
                    <PeopleListing {...peopleProps} key={idx} renderAdditionalTitle={renderAdditionalTitle} /> //prevents
                ))}
            </div>
            <ConditionalRender condition={url}>
                <div className="u-margin-top-small u-hide-in-print u-clear-both">
                    <LearnMore
                        text={viewAllText}
                        url={url}
                        color="light-blue"
                    />
                </div>
            </ConditionalRender>
        </div>
    );
}

PeopleListings.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    people: PropTypes.arrayOf(PropTypes.shape(PeopleListing.propTypes)),
    renderAdditionalTitle: PropTypes.bool,
    extraClasses: PropTypes.arrayOf(PropTypes.string),
    viewAllText: PropTypes.string,
    url: PropTypes.string,
    renderMode: PropTypes.string,
};

PeopleListings.defaultProps = {
    title: '',
    tag: 'h3',
    people: [],
    renderAdditionalTitle: false,
    extraClasses: [],
    viewAllText: 'View All Related Professionals',
    url: '',
    renderMode: '',
};

export default PeopleListings;
